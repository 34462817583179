import http from '@/axios/index.js'

/**
 * 后台登录
 * @param { Object } data
 * @param { string } data.username 用户名
 * @param { string } data.password 用户密码
*/
export function adminLogin (data) {
  return http({
    url: 'open/admin/login',
    method: 'post',
    data
  })
}

/**
 * 后台注册
 * @param { Object } data
 * @param { string } data.username 用户名
 * @param { string } data.password 用户密码
*/
export function adminRegister (data) {
  return http({
    url: 'open/admin/register',
    method: 'post',
    data
  })
}

/**
 * 获取admin用户信息
*/
export function getAdminInfo () {
  return http({
    url: 'adminapi/info',
    method: 'post'
  })
}

/**
 * admin退出登录
*/
export function adminOutLoginApi () {
  return http({
    url: 'adminapi/logout',
    method: 'post'
  })
}

/**
 * 获取商品列表
*/
export function getGourmetList (params) {
  return http({
    url: 'adminapi/getGourmetList',
    method: 'get',
    params: params
  })
}

/**
 * 获取店铺列表
 * @param { object } params
 * @param { string } param.authStatus 1已入住 0待审核
*/
export function getShopList (params) {
  return http({
    url: 'adminapi/getShopList',
    method: 'get',
    params: params
  })
}

/**
 * 新增商品
 * @param { object } data
*/
export function addGourmet (data) {
  return http({
    url: 'adminapi/addGourmet',
    method: 'post',
    data
  })
}
/**
 * 修改商品
 * @param { object } data
*/
export function updateGourmetApi (data) {
  return http({
    url: 'adminapi/updateGourmet',
    method: 'post',
    data
  })
}


/**
 * 店铺审核
 * @param { object } data
 * @param { string } data.shopId 店铺id
 * @param { string } data.isPass ture 通过 false
 * @param { string } data.explain 审核说明
*/
export function passShopApp (data) {
  return http({
    url: 'adminapi/passShopApp',
    method: 'post',
    params: data
  })
}

/**
 * 获取用户列表
*/
export function getUserList () {
  return http({
    url: 'adminapi/getUserList',
    method: 'post'
  })
}

/**
 * 获取订单列表
*/
export function getOrderList (data) {
  return http({
    url: 'adminapi/getOrderList',
    method: 'get',
    params: data
  })
}

/**
 * 上下架
*/
export function putawayGourmet (params) {
  return http({
    url: 'adminapi/putawayGourmet',
    method: 'post',
    params
  })
}

/**
 * 修改密码
*/
export function refreshPasswordApi (c) {
  return http({
    url: 'adminapi/refreshPassword',
    method: 'post',
    params
  })
}

/**
 * 订单详情
*/
export function getOrderDetailApi (params) {
  return http({
    url: 'adminapi/getOrderDetail',
    method: 'get',
    params
  })
}
/**
 * 订单核销
*/
export function orderFinishApi (params) {
  return http({
    url: 'adminapi/orderFinish',
    method: 'post',
    params
  })
}
// 获取主播能力审核列表
export function getCrateRoomApplyListApi () {
  return http({
    url: 'adminapi/getCrateRoomApplyList',
    method: 'get'
  })
}
// 提交开播能力审核
/** 
 * @param {object} params
 * @param {string} params.id
 * @param { Boolean } params.isPass
 * @param {string} params.reason
 * */
export function updateRoomApplyApi (params) {
  return http({
    url: 'adminapi/updateRoomApply',
    method: 'post',
    data: params
  })
}
// 获取直播间列表
export function getLiveRoomListApi () {
  return http({
    url: 'adminapi/getLiveRoomList',
    method: 'get',
  })
}
// 获取直播间分类列表
export function getRoomTypeListApi () {
  return http({
    url: 'adminapi/getRoomTypeList',
    method: 'get',
  })
}
// 直播间封禁
export function putBlockingApi (roomId) {
  return http({
    url: `adminapi/blocking?roomId=${roomId}`,
    method: 'put',
  })
}
// 直播间解封
export function putUnblockApi (roomId) {
  return http({
    url: `adminapi/unblock?roomId=${roomId}`,
    method: 'put',
  })
}



